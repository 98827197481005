<template>
    <div>
      <div class="row">
        <div class="col-md-12">
          <KTPortlet v-bind:title="title">
            <template v-slot:body>
              <v-form ref="form" @submit.prevent="submitForm">
                <div class="row">
                  <div class="col-6">
                    <v-text-field
                      v-model="form.searchKeyword"
                      :disabled="datatable.loading"
                      :label="$t('SHARED.KEYWORD')"
                      :hint="$t('REPORT.USE_PROMOTION_BIRTH_DATE.KEYWORD_HINT')"
                      prepend-icon="mdi-file-document-box-search-outline"
                    ></v-text-field>
                  </div>
                  
                  <div class="col-3">
                      <v-dialog
                        ref="dialogStart"
                        v-model="form.startUseDateModel"
                        :disabled="form.loading"
                        :return-value.sync="form.startUseDate"
                        persistent
                        width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="form.startUseDate"
                            :disabled="form.loading"
                            :label="$t('REPORT.USE_PROMOTION_BIRTH_DATE.START_USE_DATE')"
                            :rules="form.startUseDateRules"
                            prepend-icon="event"
                            readonly
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="form.startUseDate"
                          locale="th"
                          :disabled="form.loading"
                          scrollable
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="default"
                            @click="form.startUseDateModel = false"
                            >{{ $t("SHARED.CANCEL_BUTTON") }}</v-btn
                          >
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.dialogStart.save(form.startUseDate)"
                            >{{ $t("SHARED.CHOOSE_BUTTON") }}</v-btn
                          >
                        </v-date-picker>
                      </v-dialog>
                  </div>
  
                  <!-- endUseDate -->
                  <div class="col-3">
                    <v-dialog
                      ref="dialogEnd"
                      v-model="form.endUseDateModel"
                      :disabled="form.loading"
                      :return-value.sync="form.endUseDate"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="form.endUseDate"
                          :disabled="form.loading"
                          :label="$t('REPORT.USE_PROMOTION_BIRTH_DATE.END_USE_DATE')"
                          :rules="form.endUseDateRules"
                          prepend-icon="event"
                          readonly
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="form.endUseDate"
                        locale="th"
                        :disabled="form.loading"
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="default"
                          @click="form.endUseDateModel = false"
                          >{{ $t("SHARED.CANCEL_BUTTON") }}</v-btn
                        >
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.dialogEnd.save(form.endUseDate)"
                          >{{ $t("SHARED.CHOOSE_BUTTON") }}</v-btn
                        >
                      </v-date-picker>
                    </v-dialog>
                  </div>

                  <v-col cols="12" md="3">
                  <v-menu
                  ref="dialogStartBirthDateModal"
                    v-model="form.startBirthDateModal"
                    :disabled="form.loading"
                    :nudge-right="5"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                    >
                  <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="computedStartBirthDate"
                        :disabled="form.loading"
                        :label="$t('REPORT.USE_PROMOTION_BIRTH_DATE.START_BIRTH_DATE')"
                        required
                        prepend-icon="event"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="form.startBirthDate"
                      type="month"
                      locale="th"
                      :disabled="form.loading"
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="default"
                        @click="form.expireDateModal = false"
                        >{{ $t("SHARED.CANCEL_BUTTON") }}</v-btn
                      >
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.dialogStartBirthDateModal.save(form.startBirthDate)"
                        >{{ $t("SHARED.CHOOSE_BUTTON") }}</v-btn
                      >
                    </v-date-picker>     
                  </v-menu>
                </v-col>

                <v-col cols="12" md="3">
                  <v-menu
                  ref="dialogEndBirthDateModal"
                    v-model="form.endBirthDateModal"
                    :disabled="form.loading"
                    :nudge-right="5"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                    >
                  <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="computedEndBirthDate"
                        :disabled="form.loading"
                        :label="$t('REPORT.USE_PROMOTION_BIRTH_DATE.END_BIRTH_DATE')"
                        required
                        prepend-icon="event"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="form.endBirthDate"
                      type="month"
                      locale="th"
                      :disabled="form.loading"
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="default"
                        @click="form.expireDateModal = false"
                        >{{ $t("SHARED.CANCEL_BUTTON") }}</v-btn
                      >
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.dialogEndBirthDateModal.save(form.endBirthDate)"
                        >{{ $t("SHARED.CHOOSE_BUTTON") }}</v-btn
                      >
                    </v-date-picker>     
                  </v-menu>
                </v-col>
                </div>
                <div class="row">
                  <div class="col-12">
                    <v-btn
                      :disabled="datatable.loading"
                      color="success"
                      class="mr-4"
                      tile
                      type="submit"
                    >
                      <v-icon v-if="!datatable.loading" left
                        >mdi-database-search</v-icon
                      >
                      <v-icon v-if="datatable.loading" left
                        >fa fa-spinner fa-spin</v-icon
                      >
                      {{ $t("SHARED.SEARCH_BUTTON") }}
                    </v-btn>
                    <v-btn
                      :disabled="datatable.loading"
                      color="default"
                      class="mr-4"
                      type="reset"
                      tile
                      @click.prevent="resetForm"
                    >
                      <v-icon left>mdi-eraser</v-icon>
                      {{ $t("SHARED.RESET_BUTTON") }}
                    </v-btn>
                    <v-btn
                      :disabled="datatable.loading"
                      color="warning"
                      class="mr-4"
                      tile
                      @click.prevent="Export"
                    >
                      <v-icon v-if="!datatable.loading" left>fas fa-file-export</v-icon>
                      <v-icon v-if="datatable.loading" left>fa fa-spinner fa-spin</v-icon>
                      {{ $t("SHARED.EXPORT_BUTTON") }}
                    </v-btn>
                  </div>
                </div>
              </v-form>
  
              <v-divider class="my-4"></v-divider>
  
              <v-data-table
                :headers="datatable.headers"
                :items="datatable.items"
                :loading="datatable.loading"
                :options.sync="datatable.options"
                :server-items-length="datatable.total"
                :footer-props="{
                  'items-per-page-options': [30, 50, 100],
                }"
                multi-sort
              >
                <template v-slot:item.isUsePromotionBirthDate="{ item }">
                  <v-icon 
                    :class="getInActiveStatus(item.isUsePromotionBirthDate)">
                    {{ getInActiveStatusIcon(item.isUsePromotionBirthDate) }}
                  </v-icon>
                </template>
  
                <template v-slot:item.memberCardFileByteUrl="{ item }">
                  <v-img 
                      v-if="item.memberCardFileByteUrl"
                      :v-model="item.memberCardFileByteUrl"
                      :src="item.memberCardFileByteUrl" 
                      aspect-ratio="1.7" 
                      contain
                    >
                    </v-img>
                </template>
                
                <template v-slot:item.sumpoint="{ item }">
                  {{numberWithCommas(item.sumpoint)}}
                </template>
                <template v-slot:item.balancePoint="{ item }">
                  {{numberWithCommas(item.balancePoint)}}
                </template>
                
              </v-data-table>
  
              <v-dialog
                v-model="datatable.loading"
                persistent
                width="300"
              >
                <v-card>
                  <v-card-title class="headline">
                    {{ $t("SHARED.PLEASE_WAIT") }}</v-card-title
                  >
                  <v-card-text>
                    <p>
                      {{ $t("SHARED.PROCESSING") }}
                    </p>
                    <v-progress-linear
                      indeterminate
                      color="amber lighten-3"
                      class="mb-3"
                    ></v-progress-linear>
                  </v-card-text>
                </v-card>
              </v-dialog>
  
            </template>
          </KTPortlet>
        </div>
      </div>
    </div>
  </template>
  
<script>
import ApiService from "@/common/api.service";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import JwtService from "@/common/jwt.service";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import moment from "moment";
  
  export default {
    components: {
      KTPortlet,
    },
    data() {
      return {
        form: {
            searchKeyword: "",

            startUseDate: null,
            startUseDateModel: false,
            endUseDate: null,
            endUseDateModel: false,

            startBirthDate: null,
            startBirthDateModal: false,
            startBirthDateFormatted: "",

            endBirthDate: null,
            endBirthDateModal: false,
            endBirthDateFormatted: "",
        },
        datatable: {
          headers: [
            {
              text: this.$t("REPORT.USE_PROMOTION_BIRTH_DATE.CARD_NUMBER"),
              value: "cardNumber",
              align: "center",
            },
            {
              text: this.$t("REPORT.USE_PROMOTION_BIRTH_DATE.FIRST_NAME"),
              value: "firstName",
            },
            {
              text: this.$t("REPORT.USE_PROMOTION_BIRTH_DATE.LAST_NAME"),
              value: "lastName",
            },
            {
              text: this.$t("REPORT.USE_PROMOTION_BIRTH_DATE.BIRTH_DATE"),
              value: "birthDateString",
              align: "center",
            },
            {
              text: this.$t("REPORT.USE_PROMOTION_BIRTH_DATE.PHONE_NUMBER"),
              value: "phoneNumber",
            },
            {
              text: this.$t("REPORT.USE_PROMOTION_BIRTH_DATE.ADDRESS"),
              value: "address",
            },
            {
              text: this.$t("REPORT.USE_PROMOTION_BIRTH_DATE.BRANCH_CODE"),
              value: "branchCode",
            },
            {
              text: this.$t("REPORT.USE_PROMOTION_BIRTH_DATE.BRANCH_NAME"),
              value: "branchName",
            },
            {
              text: this.$t("REPORT.USE_PROMOTION_BIRTH_DATE.SALE_NUM"),
              value: "saleNum",
            },
            {
              text: this.$t("REPORT.USE_PROMOTION_BIRTH_DATE.TOTAL"),
              value: "total",
              align: "center",
            },
            {
              text: this.$t("REPORT.USE_PROMOTION_BIRTH_DATE.DISCOUNT"),
              value: "discount",
              align: "center",
            },
            {
              text: this.$t("REPORT.USE_PROMOTION_BIRTH_DATE.IS_USE_PROMOTION_DATE"),
              value: "isUsePromotionBirthDate",
              align: "center",
            },
            {
              text: this.$t("REPORT.USE_PROMOTION_BIRTH_DATE.USE_PROMOTION_DATE"),
              value: "usePromotionBirthDateString",
              align: "center",
            }
          ],
          items: [],
          total: 0,
          loading: true,
          options: {
            sortBy: ["cardNumber"],
            sortDesc: [false],
            itemsPerPage: 30,
          },
        },
      };
    },
    methods: {
      Export(){
        var StartBirthDateString = this.form.startBirthDateFormatted
        ? moment(this.form.startBirthDate, "YYYY-MM").startOf("month").format("DD/MM/YYYY")
        : "";
        var EndBirthDateString = this.form.endBirthDateFormatted
        ? moment(this.form.endBirthDate, "YYYY-MM").endOf("month").format("DD/MM/YYYY")
        : "";

        window.open("/Files/Export/UsePromotionBirthDate?searchKeyword="
        +this.form.searchKeyword
        +"&startUseDate="+this.form.startUseDate 
        +"&endUseDate="+this.form.endUseDate
        +"&StartBirthDateString="+StartBirthDateString
        +"&EndBirthDateString="+EndBirthDateString
        +"&token="+this.token,"_blank");
      },
      submitForm() {
        this.getDataFromApi().then((data) => {
          this.datatable.total = data.total;
          this.datatable.items = data.items;
        });
      },
      resetForm() {
        this.form.searchKeyword = "";
        this.form.startBirthDateFormatted = ""
        this.form.endBirthDateFormatted = ""
        this.form.startBirthDate = ""
        this.form.endBirthDate = ""
        // RESET START DATE
        var s = new Date();
        s.setDate(1);
        this.form.startUseDate = s.toISOString().substr(0, 10);
        var e = new Date();
        var year = new Date().getFullYear();
        var month = new Date().getMonth();
        month = month == 12 ? 0 : month + 1;
        var day = new Date(year, month, 0).getDate();
        e.setDate(day);
        this.form.endUseDate = e.toISOString().substr(0, 10);
        // RESET END DATE
        // this.$refs.form.reset();
        this.submitForm();
      },
      getDataFromApi() {
        this.datatable.loading = true;
        return new Promise((resolve) => {
          ApiService.setHeader();
          ApiService.post("/Api/Report/UsePromotionBirthDate/Search", {
            GroupBy: this.datatable.options.groupBy,
            GroupDesc: this.datatable.options.groupDesc,
            ItemsPerPage: this.datatable.options.itemsPerPage,
            Page: this.datatable.options.page,
            SortBy: this.datatable.options.sortBy,
            SortDesc: this.datatable.options.sortDesc,
            SearchKeyword: this.form.searchKeyword,
            StartUsePromotionBirthDate: this.form.startUseDate,
            EndUsePromotionBirthDate: this.form.endUseDate,
            StartBirthDateString: this.form.startBirthDateFormatted
            ? moment(this.form.startBirthDate, "YYYY-MM").startOf("month").format("DD/MM/YYYY")
            : null,
            EndBirthDateString: this.form.endBirthDateFormatted
            ? moment(this.form.endBirthDate, "YYYY-MM").endOf("month").format("DD/MM/YYYY")
            : null,
          })
            .then(({ data }) => {
              resolve({
                items: data.items,
                total: data.total,
              });
            })
            .finally(() => {
              this.datatable.loading = false;
            });
        });
      },
      getInActiveStatus(isUsePromotionBirthDate){
        if (isUsePromotionBirthDate) 
          return 'text-success';
        else 
          return 'text-danger';
      },
      getInActiveStatusIcon(isUsePromotionBirthDate){
        if (isUsePromotionBirthDate) 
          return 'check_circle';
        else 
          return 'cancel';
      },
      formatDate(date) {
        if (!date) return null;
  
        const [year, month, day] = date.split('-');
        return `${day}/${month}/${year}`;
      },
      numberWithCommas(num){
        if(num == null)
          return null;
        else
          return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
      formatMonth(date) {
        if (!date) return null;

        const [year, month] = date.split("-");
        return `${month}/${year}`;
        },
    },
    mounted() {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: this.$t("MENU.REPORT.SECTION"), route: "/Report" },
        { title: this.$t("MENU.REPORT.USE_PROMOTION_BIRTH_DATE") },
      ]);
    },
    computed: {
      title() {
        return this.$t("MENU.REPORT.USE_PROMOTION_BIRTH_DATE_SEARCH");
      },
      computedStartBirthDate() {
        return this.formatMonth(this.form.startBirthDate)
      },
      computedEndBirthDate() {
        return this.formatMonth(this.form.endBirthDate)
      },
      token() {
        return JwtService.getToken();
      },
    },
    watch: {
        "datatable.options": {
            handler() {
            if (!this.form.startUseDate) {
                var s = new Date();
                s.setDate(1);
                this.form.startUseDate = s.toISOString().substr(0, 10);
            }
    
            if (!this.form.endUseDate) {
                var e = new Date();
                var year = new Date().getFullYear();
                var month = new Date().getMonth();
                month = month == 12 ? 0 : month + 1;
                var day = new Date(year, month, 0).getDate();
                e.setDate(day);
                this.form.endUseDate = e.toISOString().substr(0, 10);
            }
    
            this.getDataFromApi().then((data) => {
                this.datatable.total = data.total;
                this.datatable.items = data.items;
            });
            },
            deep: true,
        },
      "form.startBirthDate": {
            handler() {
                this.form.startBirthDateFormatted = this.formatMonth(this.form.startBirthDate);
            }
        },
        "form.endBirthDate": {
            handler() {
                this.form.endBirthDateFormatted = this.formatMonth(this.form.endBirthDate);
            }
        },
    },
  };
  </script>
  
  <style lang="scss" scoped></style>
  